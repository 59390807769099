import React from 'react';
import {connect} from 'react-redux';

const Footer = props => (

   <div id={'Footer'} className="footer-wrapper">
      <div className="footer">
         <div className="container">
            <div className="row">
               <div className="col-12 col-xl-5 col-md-5 col-sm-6"/>
               <div className="col-12 col-xl-5 col-md-1 col-sm-1">
               </div>
               <div className="col-2 col-xl-2 d-none d-md-block">
                  <h3>Rechtliches</h3>
                  <div>
                     <a target="_blank" href={'https://automeister.de/nutzungsbedingungen'}> Nutzungsbedingungen </a>
                  </div>
                  <div>
                     <a target="_blank"
                        href={'https://automeister.de/datenschutzerklaerung'}> Datenschutzerklärung </a>
                  </div>
                  <div>
                     <a target="_blank" href={'https://automeister.de/impressum'}> Impressum </a>
                  </div>
               </div>
               <div className="col-1 col-xl-2 d-none d-md-block">
               </div>
            </div>
         </div>
      </div>
   </div>
);

const mapStateToProps = state => ({
   loggedIn: state.loggedIn,
   firmData: state.firmData,
   domain: state.domain
});
export default connect(mapStateToProps)(Footer);
