import React from 'react';
import {connect} from "react-redux";

class Header extends React.Component {
   constructor(props) {
      super(props);
   }

   render() {
      return (
         <div className={'header marg-top-header'}>
            <div className={'container'}>
               <div className="row">
                  <div className="col-4">
                     <a href={'/'}>
                        <img src="/am_logo.png" className="img-fluid logo-size"/>
                     </a>
                  </div>
                  <div className="col-8">
                     <h1 className="header-title">
                        {this.props.firmData ? this.props.firmData[0].AllgemeineDaten.Name : ''}
                     </h1>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => ({
   firmData: state.firmData,
   domain: state.domain
});

const mapDispatchToProps = dispatch => {
   return {
      dispatch,

   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

